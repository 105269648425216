<template>
  <div
  class="modal"
  v-bind:class="{'is-active': show}"
  >
    <div @click="hide()" class="modal-background pointer"></div>
    <div class="modal-card px-3">
      <section class="modal-card-body has-rounded-corners">
        <div class="container has-text-centered pt-2">
          <div class="is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color">
            Background Music
          </div>
        </div>
        <div class="container has-text-centered pt-2 mb-5">
          <div class="is-size-6 has-text-grey">
            Music is played on loop and can be stopped anytime. Volume can be adjusted, depending on device compatibility.
          </div>
        </div>
        <div v-if="musicUrl" class="container has-text-centered mb-5">
          <audio ref="audioPlayer" autoplay="autoplay" controls="controls" loop="loop">
            <source :src="musicUrl" />
          </audio>
        </div>
      </section>
    </div>
    <button @click="hide()" class="modal-close is-large" aria-label="close"></button>
  </div>
</template>

<script>
export default {
  name: 'BackgroundMusic',
  props: ['show'],
  methods: {
    hide () {
      this.$emit('hide', false)
    },
    resetAudio() {
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.load()
        this.$refs.audioPlayer.play()
      }
    }
  },
  watch: {
    'adventureTeam.stage'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.resetAudio()
      }
    }
  },
  computed: {
    adventureTeam() {
      return this.$store.state.adventureTeam
    },
    currentStage() {
      return this.$store.state.currentStage
    },
    musicUrl() {
      if (this.adventureTeam && this.adventureTeam.stageDetails) {
        return this.adventureTeam.stageDetails.chapter_music_url
      } else {
        return null
      }
    }
  }
}
</script>

<style>

</style>
