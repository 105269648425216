<template>
  <nav id="navbar-bottom" class="is-flex navbar is-gapless is-mobile is-fixed-bottom p-2 has-shadow is-justify-content-center unselectable game-navbar-modifier" role="navigation" aria-label="main navigation">
    <div
    v-if="adventureTeam && adventureTeam.hasItems"
    @click="goTo('Inventory')"
    class="has-text-centered is-2 py-0 my-0 mx-2 pointer navbar-button-modifier"
    >
      <i v-if="currentRouteName !== 'Inventory'" class="fas fa-lg fa-box"></i>
      <i v-if="currentRouteName === 'Inventory'" class="fas fa-lg fa-box-open"></i>
      <i v-if="inventoryViews && !showInventoryAlert" class="has-text-grey-light fas fa-sm fa-eye navbar-icon">{{ inventoryViews }}</i>
      <i v-if="showInventoryAlert" class="has-text-danger fas fa-md fa-exclamation-circle flash navbar-icon"></i>
      <div key="inventory-navbar" class="is-size-7" v-bind:class="{ 'has-text-weight-bold': currentRouteName === 'Inventory' }">
        {{ t(`${$store.state.uiMods.inventory}`) }}
      </div>
    </div>
    <div
    v-if="adventureTeam && adventureTeam.hasScannableItems"
    @click="goTo('Scavenge')"
    class="has-text-centered is-2 py-0 my-0 mx-2 pointer navbar-button-modifier"
    >
      <i v-if="currentRouteName !== 'Scavenge'" class="fas fa-lg fa-qrcode"></i>
      <i v-if="currentRouteName === 'Scavenge'" class="fas fa-lg fa-camera"></i>
      <i v-if="scavengeViews" class="has-text-grey-light fas fa-sm fa-eye navbar-icon">{{ scavengeViews }}</i>
      <div key="scavenge-navbar" class="is-size-7" v-bind:class="{ 'has-text-weight-bold': currentRouteName === 'Scavenge' }">
        {{ t(`${$store.state.uiMods.scan}`) }}
      </div>
    </div>
    <div
    v-if="adventureTeam && (adventureTeam.hasSearchableItems || adventureTeam.allowTeamPositionsView)"
    @click="goTo('MapView')"
    class="has-text-centered is-2 py-0 my-0 mx-2 pointer navbar-button-modifier"
    >
      <i v-if="currentRouteName !== 'MapView'" class="fas fa-lg fa-map"></i>
      <i v-if="currentRouteName === 'MapView'" class="fas fa-lg fa-map-marked-alt"></i>
      <i v-if="mapViews" class="has-text-grey-light fas fa-sm fa-eye navbar-icon">{{ mapViews }}</i>
      <div key="map-navbar" class="is-size-7" v-bind:class="{ 'has-text-weight-bold': currentRouteName === 'MapView' }">
        {{ t(`Map`) }}
      </div>
    </div>
    <div
    v-if="adventureTeam && adventureTeam.powerups"
    @click="goTo('Powerups')"
    class="has-text-centered is-2 py-0 my-0 mx-2 pointer navbar-button-modifier"
    >
      <i v-if="currentRouteName !== 'Powerups'" class="fas fa-lg fa-bolt"></i>
      <i
      v-if="currentRouteName === 'Powerups'"
      class="fas fa-lg fa-crosshairs">
      </i>
      <i v-if="showPowerupAlert" class="has-text-danger fas fa-md fa-exclamation-circle flash navbar-icon"></i>
      <div key="stage-navbar" class="is-size-7" v-bind:class="{ 'has-text-weight-semibold': currentRouteName === 'Stage' }">
        {{ t(p(adventureTeam, 'powerup', 2, false)) }}
      </div>
    </div>
    <div
    @click="goTo('Stage')"
    class="has-text-centered is-2 py-0 my-0 mx-2 pointer navbar-button-modifier"
    >
      <i v-if="currentRouteName !== 'Stage'" class="fas fa-lg fa-clipboard"></i>
      <i
      v-if="currentRouteName === 'Stage'"
      class="fas fa-lg"
      v-bind:class="{
        'fa-clipboard-check': adventureTeam && adventureTeam.accessType !== 'public',
        'fa-map': adventureTeam && adventureTeam.accessType === 'public'
      }">
      </i>
      <i v-if="stageViews" class="has-text-grey-light fas fa-sm fa-eye navbar-icon">{{ stageViews }}</i>
      <i v-if="showTaskAlert" class="has-text-danger fas fa-md fa-exclamation-circle flash navbar-icon"></i>
      <div  key="task-navbar" class="is-size-7" v-bind:class="{ 'has-text-weight-semibold': currentRouteName === 'Stage' }">
        {{ t(`${$store.state.uiMods.task ? `${p(adventureTeam, 'task', 2, false)}` : t('Tasks')}`) }}
      </div>
    </div>
    <div
    @click="goTo('Chat')"
    class="has-text-centered is-2 py-0 my-0 mx-2 pointer navbar-button-modifier"
    v-if="showChatButton"
    >
      <i v-if="currentRouteName !== 'Chat'" class="fas fa-lg fa-comment"></i>
      <i v-if="currentRouteName === 'Chat'" class="fas fa-lg fa-comment-dots"></i>
      <i v-if="chatViews && !showChatAlert" class="has-text-grey-light fas fa-sm fa-eye navbar-icon">{{ chatViews }}</i>
      <i v-if="showChatAlert" class="has-text-danger fas fa-md fa-exclamation-circle flash navbar-icon"></i>
      <div key="chat-navbar" class="is-size-7" v-bind:class="{ 'has-text-weight-bold': currentRouteName === 'Chat' }">
        {{ t(`${$store.state.uiMods.chat}`) }}
      </div>
    </div>
    <div
    @click="goTo('InviteTeam')"
    class="has-text-centered is-2 py-0 my-0 mx-2 pointer navbar-button-modifier"
    v-if="adventureTeam && adventureTeam.accessType !== 'public' && (!adventureTeam.maxPlayersPerTeam || (adventureTeam.maxPlayersPerTeam && adventureTeam.maxPlayersPerTeam > 1))"
    >
      <i v-if="currentRouteName !== 'InviteTeam'" class="fas fa-lg fa-user-plus"></i>
      <i v-if="currentRouteName === 'InviteTeam'" class="fas fa-lg fa-user-friends"></i>
      <div key="invite-navbar" class="is-size-7" v-bind:class="{ 'has-text-weight-bold': currentRouteName === 'InviteTeam' }">
        {{ t(`Invite`) }}
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'GameNavbar',
  methods: {
    goTo(routeName){
      if(this.currentRouteName !== routeName){
        if(
          routeName === 'Stage' &&
          this.currentRouteName !== 'Challenge' &&
          this.currentChallengeIndex
        ){
          this.$router.push({
              name: 'Challenge',
              params: {
                stageIndex: this.currentStage,
                challengeIndex: this.currentChallengeIndex+1
              }
            }
          )
        } else {
          this.$router.push({ name: routeName })
        }
      }
    }
  },
  computed: {
    adventureTeam() {
      return this.$store.state.adventureTeam
    },
    currentRouteName() {
      return this.$route.name
    },
    currentStage() {
      return this.$store.state.currentStage
    },
    currentChallengeIndex() {
      return this.$store.state.currentChallengeIndex
    },
    showTaskAlert() {
      return this.$store.state.showTaskAlert
    },
    showInventoryAlert() {
      return this.$store.state.showInventoryAlert
    },
    showChatAlert() {
      return this.$store.state.showChatAlert
    },
    showChatButton() {
      if (this.$store.state.adventureTeam) {
        // should show Chat if any of this is true
        const teamTest = this.adventureTeam.teamMembers.length > 1
        const maxPlayersTest = this.adventureTeam.maxPlayersPerTeam && this.adventureTeam.maxPlayersPerTeam > 1
        const chatsTest = this.adventureTeam.chats && this.adventureTeam.chats.length > 0
        const teamToFaciTest = this.adventureTeam.allowFaciMessaging
        const teamToTeamTest = this.adventureTeam.allowSessionChat
        return (
          teamTest || maxPlayersTest || chatsTest || teamToFaciTest || teamToTeamTest
        )
      } else {
        return false
      }
    },
    showPowerupAlert() {
      // return false
      // Note: Because player 2 onwards will see that the powerup selection is fake, so we hide the notifications.
      return this.$store.state.showPowerupAlert
    },
    viewsStats() {
      return this.$store.state.adventureView ? this.$store.state.adventureView.stats : null
    },
    inventoryViews() {
      if(this.viewsStats && this.viewsStats['inventoryundefinedundefined']) {
        return this.viewsStats['inventoryundefinedundefined']
      } else {
        return 0
      }
    },
    scavengeViews() {
      if(this.viewsStats && this.viewsStats['scavengeundefinedundefined']) {
        return this.viewsStats['scavengeundefinedundefined']
      } else {
        return 0
      }
    },
    mapViews() {
      if(this.viewsStats && this.viewsStats['mapViewundefinedundefined']) {
        return this.viewsStats['mapViewundefinedundefined']
      } else {
        return 0
      }
    },
    stageViews() {
      if(this.viewsStats) {
        let views = 0
        for (const [key, value] of Object.entries(this.viewsStats)) {
          if(key.includes('stage') || key.includes('challenge')) {
            views = views + 1
          }
        }
        return views
      } else {
        return 0
      }
    },
    chatViews() {
      if(this.viewsStats && this.viewsStats['chatundefinedundefined']) {
        return this.viewsStats['chatundefinedundefined']
      } else {
        return 0
      }
    }
  }
}
</script>

<style>
.flash {
  animation-name: flash;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-play-state: running;
}

.flash-slow {
  animation-name: flash;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-play-state: running;
}

@keyframes flash {
  from {opacity: 1;}
  to {opacity: 0;}
}

.navbar-icon {
  transform: translateX(-5px) translateY(-10px);
}

.game-navbar-modifier {
  margin-bottom: 0px !important;
  z-index: 1000 !important;
}

.navbar-button-modifier {
  /* width: 70px; */
}
</style>
